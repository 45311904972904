<template>
    <div>
        <div class="main">
            <div class="title">
                用户中心
            </div>

            <div class="box">
                <div class="avatar">
                    <div class="smallTitle">
                        头像
                    </div>
                    <el-upload
                        class="avatar-uploader"
                        action="https://www.kmdnkj.com/api/index/index/method/images.upload"
                        style="border:1px #666 dashed;height:100px;width:100px;border-radius:50%;line-height:100px"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar_image">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
               
                </div>
                <div class="name" v-if="show_name">
                    <div class="smallTitle">
                        用户名
                    </div>
                    <input type="text" v-model="user_name">
                    <img src="../assets/input.png" alt="" srcset="">
                </div>
                <div class="name" v-if="show_phone">
                    <div class="smallTitle">
                        老手机号
                    </div>
                    <input type="text" v-model="old_user_mobile">
                    <img src="../assets/input.png" alt="" srcset="">
                </div>
                <div class="name" v-if="show_phone">
                    <div class="smallTitle">
                        新手机号
                    </div>
                    <input type="text" v-model="new_user_mobile">
                    <img src="../assets/input.png" alt="" srcset="">
                </div>
                <div class="name" v-if="show_phone">
                    <div class="smallTitle">
                        验证码
                    </div>
                    <input type="number" v-model="code" maxlength="6" style="width:100px">
                     <div @click="send_code" v-if="show_send" class="get-code">获取验证码</div>
                    <div class="get-code" v-else>{{time}}秒后重新发送</div>
                    <img src="../assets/input.png" alt="" srcset="">
                </div>
                <!-- <div class="baocun" @click="goback">保存</div> -->
                <el-button plain type="primary" class="baocun" @click="goback">保存</el-button> 
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            user_name:'',
            user_mobile:'',
            new_user_mobile:'',
            imageUrl:'',
            code:'',
            show_send:true,
            time:60,
            show_name:false,
            show_phone:false,

        }
    },
    created(){
        if(this.$route.query.quary=='name'){
            this.show_name = true
        }else{
            this.show_phone = true
        }
       this.imageUrl = JSON.parse(this.$local.get('userinfo')).avatar
       this.user_name = JSON.parse(this.$local.get('userinfo')).nickname
       this.old_user_mobile = JSON.parse(this.$local.get('userinfo')).mobile
    },

    methods:{
        send_code(){
            let data = {
                mobile:this.new_user_mobile,
                code:'bind'
            }
            this.$api.userpost('user.sms',data,res=>{
                if(res.data.status){
                    this.$message({
                        type:'success',
                        message:'发送成功'
                    })
                    this.show_send = false
                    var interval = setInterval(() => {
                    if(this.time>0){
                       
                         this.time--
                        }else{
                            console.log('2')
                                this.time = 60
                                this.show_send = true
                               
                                clearInterval(interval)
                            }
                                    
                     }, 1000);
                }else{
                    this.$message.error(res.data.msg)
                }
            })
            
        },
         handleAvatarSuccess(res, file) {
             
            this.imageUrl = res.data.url;
            this.image_id  = res.data.image_id;
            this.$api.userpost('user.changeavatar',{avatar:this.imageUrl},(res)=>{
                if(res.data.status){
                    this.$message({
                        message:'头像更换成功',
                        type:'success'
                    })
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            // this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            // return isJPG && isLt2M;
            return isLt2M;

      },
        goback(){
            if(this.show_phone){
                let data ={
                    mobile:this.new_user_mobile,
                    code:this.code
                }
                this.$api.userpost('user.bindMobile',data,res=>{
                    if(res.data.status){
                        this.$message({
                            type:'success',
                            message:'保存成功',
                            showClose:true
                        })
                        this.$router.go(-1)
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            }else{
               this.$api.userpost('user.editinfo',{nickname:this.user_name},res=>{
                   if(res.data.status){
                        this.$message({
                            type:'success',
                            message:'保存成功',
                            showClose:true
                        })
                        this.$router.go(-1)
                   }else{
                        this.$message.error(res.data.msg)
                   }
               }) 
            }
           
        },   
    }
}
</script>
<style lang="scss" scoped>
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar_image{
        height: 100px;
        width: 100px;
        border-radius: 50%;
    }
    .box{
        margin-top: 15px;
        width: 1140px;
        // height: 467px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 10px;    
    }
    .avatar{
        margin: 0 auto;
        height: 145px;
        width: 1036px;
        border-bottom: 1px solid #E9E9E9;
        display: flex;
        align-items: center;
    }
     .name{
        margin: 0 auto;
        height: 84px;
        width: 1036px;
        border-bottom: 1px solid #E9E9E9;
        display: flex;
        align-items: center;
        input{
            outline-style: none;
            border: none;
            font-size: 18px;
        }
    }
    .smallTitle{
        width: 120px;
        text-align: left;
        font-size:18px ;
    }
    .baocun{
        width: 210px;
        height: 49px;
        // background: linear-gradient(162deg, #3C7DE2 0%, #2163C7 100%);
        // opacity: 1;
        // border-radius: 25px;
        // line-height: 49px;
        // text-align: center;
        // font-size:23px;
        // color: #FFF;
        margin: 51px 0 0 33px;
    }
</style>